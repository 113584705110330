.password-input {
  height: var(--controlSizeLG);

  border-radius: var(--controlBorderRadius);

  padding: var(--inputPaddingVertical) var(--inputPaddingHorizontal);

  &.error {
    background-color: var(--bgError);
    & > input {
      background-color: var(--bgError);
    }
  }

  &:focus-within {
    box-shadow: none;
  }

  & > input.ant-input {
    font-size: var(--inputFontSize);
    line-height: var(--inputLineHeight);

    font-family: var(--primaryFont);
    font-weight: var(--normal);
  }
}

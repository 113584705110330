.sales-department-card {
  padding: 30px 32px;
  background-color: var(--white100);

  &__body {
    margin-top: 24px;
    padding: 24px 32px 32px;
    background-color: var(--storm6);
    width: 100%;
  }

  &__employees-title {
    font-size: 16px;
    color: var(--black100);
    font-weight: 500;
  }

  &__employee-name {
    font-size: 16px;
    color: var(--black72);
  }

  &__info {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 16px;
  }

  &__title {
    .title {
      margin: 0;
    }
  }

  &__collapse-button {
    background-color: transparent;
    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.sales-department-card-loader {
  height: var(--cardLoaderHeightSM);
  background-color: var(--white100);
}

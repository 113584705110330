.order-sales-list-actions-loader {
  height: var(--cardActionLoaderHeight);
  background-color: var(--white100);

  margin-bottom: var(--marginXL);
}

.order-sales-list-loader {
  height: var(--cardLoaderHeightMD);
  background-color: var(--white100);
}

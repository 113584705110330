.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.xxl {
    width: var(--iconSizeXXL);
    height: var(--iconSizeXXL);

    & svg {
      transform: scale(var(--iconScaleXXL));
    }
  }

  &.xl {
    width: var(--iconSizeXL);
    height: var(--iconSizeXL);

    & svg {
      transform: scale(var(--iconScaleXL));
    }
  }

  &.lg {
    width: var(--iconSizeLG);
    height: var(--iconSizeLG);

    & svg {
      transform: scale(var(--iconScaleLG));
    }
  }

  &.md {
    width: var(--iconSizeMD);
    height: var(--iconSizeMD);

    & svg {
      transform: scale(var(--iconScaleMD));
    }
  }

  &.sm {
    width: var(--iconSizeSM);
    height: var(--iconSizeSM);

    & svg {
      transform: scale(var(--iconScaleSM));
    }
  }

  &.xs {
    width: var(--iconSizeXS);
    height: var(--iconSizeXS);

    & svg {
      transform: scale(var(--iconScaleXS));
    }
  }
}

.graph-container {
  padding: var(--paddingXXL);
  display: flex;
  flex-direction: column;
  gap: var(--marginXL);
  min-height: 50vh;

  background-color: var(--white100);
}

.graph-loader {
  margin: auto;
}

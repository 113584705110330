.statistics-container {
  display: flex;
  flex-direction: column;
  background-color: var(--white100);
  padding: var(--paddingXXL);
  gap: var(--marginLG);

  .info {
    background-color: var(--storm6);
    padding: var(--paddingXL);
  }

  .name {
    font-size: var(--fontSize);
    font-family: var(--primaryFont);
  }

  .price {
    font-size: var(--fontSize);
    font-weight: 600;
  }
}

@font-face {
  font-family: 'GrtskExa';
  src: url(./GrtskExa-normal-400-100.otf) format('opentype');
}

@font-face {
  font-family: 'GrtskExa';
  font-weight: 500;
  src: url(./GrtskExaMedium-normal-500-100.ttf) format('truetype');
}

@font-face {
  font-family: 'GrtskExa';
  font-weight: 600;
  src: url(./GrtskExaSemibold-normal-600-100.otf) format('opentype');
}

@font-face {
  font-family: 'GrtskTera';
  src: url(./GrtskTera-normal-400-100.otf) format('opentype');
}

@font-face {
  font-family: 'GrtskTera';
  font-weight: 500;
  src: url(./GrtskTera-Semibold.ttf) format('truetype');
}

@font-face {
  font-family: 'GrtskTera';
  font-weight: 700;
  src: url(./GrtskTera-normal-700-100.otf) format('opentype');
}

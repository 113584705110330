.time-picker.ant-picker {
  width: 100%;
  height: var(--controlSizeLG);

  border-radius: var(--controlBorderRadius);
  font-size: var(--inputFontSize);
  line-height: var(--inputLineHeight);

  padding: var(--inputPaddingVertical) var(--inputPaddingHorizontal);

  background-color: var(--storm6);

  font-family: var(--primaryFont);
  font-weight: var(--normal);

  color: var(--black100);

  box-shadow: none;

  & .ant-picker-input {
    & > input {
      &::placeholder {
        font-family: var(--primaryFont);
        font-weight: var(--normal);

        color: var(--black36);
      }
    }
  }

  &.white {
    background: var(--white100);
  }

  &:focus {
    box-shadow: none;
  }
}

.time-picker-popup {
  & .ant-picker-ok button {
    color: var(--lime);

    background-color: var(--transparent);

    &:disabled {
      color: var(--black48);
    }
    &:hover:not(:disabled) {
      background-color: var(--lime);
    }
  }
}

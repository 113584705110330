.info-container {
  padding: var(--paddingXXXL);
  gap: var(--marginLG);
  height: 100%;
  min-height: 50vh;
  background-color: var(--white100);

  .heading {
    margin-top: 0;
    margin-bottom: var(--marginXL);
    font-family: var(--primaryFont);
    font-size: var(--fontSizeSM);
    font-weight: var(--bold);
    line-height: var(--lineHeightH4px);
  }

  .amount {
    width: 30px;
    font-family: var(--primaryFont);
    font-size: var(--fontSizeSM);
    font-weight: var(--semibold);
    line-height: var(--textLineHeightMD);
  }

  .title {
    font-family: var(--primaryFont);
    font-size: var(--fontSizeXS);
    line-height: var(--textLineHeightMD);
    color: var(--black72);
  }
}

.reject-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  font-family: var(--secondaryFont);

  display: flex;
  align-items: center;
  gap: var(--marginLG);

  &.normal {
    color: var(--black100);
  }

  &.gray {
    color: var(--black48);
  }

  margin: 0;
}

.chip {
  display: flex;
  align-items: center;

  background-color: var(--storm6);
  color: var(--black100);

  height: var(--controlSizeXS);
  padding-inline: var(--paddingLG);

  line-height: var(--textLineHeightXXS);
  font-size: var(--textFontSizeSM);
  font-weight: var(--medium);

  border-radius: var(--controlBorderRadius);

  width: max-content;

  text-transform: capitalize;

  cursor: pointer;

  &.selected {
    background-color: var(--lime);
    color: var(--white100);
  }
}

.calendar-container {
  & .calendar-actions {
    button {
      display: flex;
      align-items: center;

      min-width: var(--controlSizeXS);
      min-height: var(--controlSizeXS);
      cursor: pointer;
      background-color: var(--white100);
      color: var(--black100);

      border: 1px solid var(--storm24);

      &.toDay {
        border-left: none;
        border-right: none;

        padding-inline: var(--paddingXL);
      }
    }
  }

  & .field {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    width: 100px;
    height: 40px;

    font-size: var(--textFontSizeSM);
    line-height: var(--textLineHeightSM);
    font-family: var(--primaryFont);
    font-weight: var(--normal);

    background-color: var(--white100);
    border: 1px solid var(--storm12);

    &.head {
      cursor: default;
    }

    &.head,
    &.dayOff {
      background-color: var(--storm12);
      border-color: var(--storm24);
    }
  }

  & .day-container {
    position: relative;
    overflow: hidden;

    & .event,
    & .busy {
      position: absolute;
      width: 100%;
    }

    & .event {
      background-color: var(--lime);
      color: var(--white100);

      font-size: var(--textFontSizeXS);
      line-height: var(--textLineHeightXS);
    }

    & .busy {
      background-color: var(--warning);
      border-color: var(--warning);
    }
  }
}

@mixin drop-down-menu($bgc: var(--storm6)) {
  padding: 0;
  box-shadow: none;

  margin-top: var(--negativeMarginXS);

  border-radius: var(--noBorder);

  background-color: $bgc;
  color: var(--black100);

  border-right: 1px solid var(--black12);
  border-left: 1px solid var(--black12);

  &.small {
    & .ant-select-item {
      padding: 10px 12px;
      font-size: var(--fontSizeXS);
    }
  }

  &.top {
    border-top: 1px solid var(--black12);

    &.small {
      border-top-left-radius: var(--borderSM);
      border-top-right-radius: var(--borderSM);
    }
  }
  &.bottom {
    border-bottom: 1px solid var(--black12);

    &.small {
      border-bottom-left-radius: var(--borderSM);
      border-bottom-right-radius: var(--borderSM);
    }
  }

  & .ant-select-item {
    line-height: var(--inputLineHeight);
    font-size: var(--inputFontSize);
    font-weight: var(--normal);

    border-radius: var(--noBorder);
    padding: var(--inputPaddingVertical) var(--inputPaddingHorizontal);

    &.ant-select-item-option-active {
      background-color: var(--storm12);
    }

    &.ant-select-item-option-selected {
      font-size: var(--inputFontSize);
      background-color: var(--lime);
      color: var(--white100);
    }
  }
}

@mixin select-menu-div($bgc: var(--storm6)) {
  display: block;

  position: absolute;

  width: 100%;

  height: var(--controlSizeLG);

  background-color: $bgc;

  z-index: 1;
  opacity: 0;

  box-sizing: border-box;
  border-right: 1px solid var(--black12);
  border-left: 1px solid var(--black12);

  top: 0;

  &.top,
  &.bottom {
    opacity: 1;
  }

  &.bottom {
    top: 50%;
  }

  &.top {
    top: auto;
    bottom: 50%;
  }
}

.badge {
  background-color: var(--lime);
  color: var(--white100);
  padding: var(--paddingXS) var(--paddingMD) var(--paddingXS6);

  line-height: var(--textFontSizeSM);
  font-size: var(--fontSizeXS);
  font-weight: var(--medium);

  border-radius: var(--controlBorderRadius);

  width: max-content;

  &.yellow {
    background-color: var(--warning);
    color: var(--black72);
  }

  &.grifel {
    background-color: var(--grifel);
  }

  &.storm12 {
    background-color: var(--storm12);
    color: var(--black100);
  }
}

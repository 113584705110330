.Header {
  &__layout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__content {
    width: 100%;
    padding: 36px 0;
  }
}

.Header-user {
  color: var(--black64);
  .text {
    font-size: var(--textFontSizeMD);
  }
}

.Header-timezone {
  margin-right: 18px;
}

.Header-menu {
  &__link {
    color: var(--black100);
    &_active {
      color: var(--lime);
    }
  }
  &__button {
    padding: 0;
  }
}

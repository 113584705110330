.switch-wrapper {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--paddingMD);

  & .switch-label {
    cursor: pointer;

    font-size: var(--textFontSizeMD);
    line-height: var(--textLineHeightSM);

    font-weight: var(--normal);
  }
}

.input-wrapper {
  width: 100%;

  &.read-only {
    pointer-events: none;
  }

  .input-container {
    margin-top: var(--marginSM);
  }
}

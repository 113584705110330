@mixin scrollbar() {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: var(--storm24);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--lime);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--darkLime);
  }
}

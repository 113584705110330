.date-picker-range {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  gap: var(--gap);

  & .date-picker {
    width: auto;
    flex: 1;
  }

  & .divider {
    border: 1px solid var(--black48);
    width: var(--gap);
  }
}

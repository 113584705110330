.custom-drawer {
  &.ant-drawer-content {
    background-color: var(--white100);
  }
  &__button-wrapper {
    position: absolute;
    z-index: 1;
    left: -24px;
    top: 64px;
  }
  & .ant-drawer-body {
    padding: 48px 48px 48px 64px;
  }
}

.description {
  margin-top: var(--marginSM);
  color: var(--black100);

  &.error {
    color: var(--mainError);
  }
  &.warning {
    color: var(--warning);
  }
}

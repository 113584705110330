@import '@/assets/styles/scrollbar';

.drawer-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 36px;
  .custom-drawer & {
    height: 100%;
  }
  &__header {
    flex: 0 0 auto;
  }
  &__body {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollbar();
    padding-right: 16px;
  }
  &__footer {
    flex: 0 0 auto;
  }
}

@import '@/assets/styles/dropdown.scss';

.phone-input-group {
  position: relative;

  height: var(--controlSizeLG);

  & .ant-select-customize-input.ant-select-single.phone-select {
    z-index: 10;

    display: flex;
    align-items: center;
    width: 100%;
    background: var(--storm24);

    border-radius: var(--controlBorderRadius);

    height: 100%;

    & .ant-select-selector {
      height: 100%;
      flex-direction: row-reverse;
      align-items: center;

      padding-left: var(--paddingLG);

      & .ant-select-selection-item {
        padding-inline-end: var(--iconSizeLG);
        height: var(--iconSizeLG);
      }
    }

    & .ant-select-arrow {
      inset-inline-start: calc(var(--paddingLG) + var(--iconSizeLG));
      inset-inline-end: auto;

      width: var(--iconSizeMD);
      height: var(--iconSizeMD);

      top: var(--paddingLG);
      margin-top: 0;

      color: var(--black100);

      transform: rotate(0deg);
      transition: transform 220ms ease-in-out;
    }

    &.ant-select-open {
      & .ant-select-arrow {
        transform: rotate(180deg);
      }
    }

    &.read-only {
      & .ant-select-arrow {
        color: var(--black24);
      }
    }
  }

  .phone-input-container {
    position: absolute;
    top: 0px;
    left: var(--languageSelectWidth);
    z-index: 11;

    height: 100%;

    width: calc(100% - var(--languageSelectWidth));

    border-radius: 0 var(--controlBorderRadius) var(--controlBorderRadius) 0;
    background-color: var(--storm6);
    border: 1px solid var(--storm6);

    display: flex;
    align-items: center;

    &.menu-open {
      border: 1px solid var(--black12);
    }

    &.error {
      background-color: var(--bgError);
      border: 1px solid var(--mainError);

      & > * {
        color: var(--mainError);
      }
    }

    &.read-only {
      & > * {
        color: var(--black36);
      }
    }

    & .phone-input {
      z-index: 10;
      flex: 1;
      background-color: var(--transparent);
      border: var(--noBorder);
      outline: none;

      padding-left: var(--paddingSM);

      font-family: var(--primaryFont);
      font-size: var(--textFontSizeMD);
      line-height: var(--textLineHeightMD);

      &:focus {
        border: var(--noBorder);
      }
    }

    & .phone-error-icon {
      z-index: 11;
      color: var(--mainError);
      position: absolute;

      right: var(--inputPaddingHorizontal);
      top: var(--paddingLG);
    }
  }

  & .select-menu-div {
    @include select-menu-div();
  }
}

.select-country-popup {
  @include drop-down-menu();
}

.country-icon {
  display: inline-flex;
  width: var(--iconSizeLG);
  height: var(--iconSizeLG);

  &.AZ {
    background: url('../../../assets/icons/countryFlags/azerbaijan-flag.png');
  }
  &.AM {
    background: url('../../../assets/icons/countryFlags/armenia-flag.png');
  }
  &.BY {
    background: url('../../../assets/icons/countryFlags/belarus-flag.png');
  }
  &.KZ {
    background: url('../../../assets/icons/countryFlags/kazakhstan-flag.png');
  }
  &.KG {
    background: url('../../../assets/icons/countryFlags/kyrgyzstan-flag.png');
  }
  &.RU {
    background: url('../../../assets/icons/countryFlags/russia-flag.png');
  }
}

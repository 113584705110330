.order-card {
  cursor: pointer;
  padding: var(--paddingSM) var(--paddingCard);

  background-color: var(--white100);

  .order-cart-title {
    margin-right: var(--marginSM);
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
  }

  & .order-card-content {
    padding: var(--paddingXL) 0px var(--paddingXL) var(--paddingCard);
  }

  & .order-info {
    background-color: var(--storm6);
    padding-block: var(--paddingLG);
  }

  & .order-card-side {
    padding-block: var(--paddingMD);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
  }

  .divider {
    height: var(--iconSizeSM);
    border-left: 1px solid var(--black48);
  }
}

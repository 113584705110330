.order-form-loader {
  background-color: var(--white100);

  height: calc(100vh - var(--orderFormSpaceSize));
}

.order-form-side-loader {
  background-color: var(--white100);

  height: calc(100vh - var(--orderFormSpaceSize));
}

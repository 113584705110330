.order-list-loader {
  height: var(--cardLoaderHeightXL);
  background-color: var(--white100);
}

.order-list-actions-loader {
  height: var(--cardActionLoaderHeight);
  background-color: var(--white100);
}

.order-list-dropdown {
  width: 100%;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--storm12);
  margin-top: 5px;
  position: absolute;
  display: flex;
  overflow: hidden; /* Скрытие контента, который не помещается в блоке */
  max-height: 0;
  transition: all 0.3s ease-in;
  z-index: 1000;

  &__item {
    padding: 14px 24px;
    cursor: pointer;
    background-color: var(--white100);
    text-align: center;
    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:hover {
      background-color: var(--storm12);
    }
  }
}

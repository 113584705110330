.card-wrapper {
  width: 100%;
  background-color: var(--white100);

  &.default {
    padding: var(--paddingXXXL);
  }

  &.wide {
    padding: var(--paddingXXL);
  }

  & .card-icon {
    & span {
      transform: rotate(0deg);
      transition: transform 220ms ease-in-out;
    }

    &.expanded span {
      transform: rotate(180deg);
    }
  }
}

.project-card {
  padding: 30px 32px;
  background-color: var(--white100);

  &__body {
    padding-top: 24px;
  }

  &__info {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 16px;
  }

  &__title {
    .title {
      margin: 0;
    }
  }

  &__collapse-button {
    background-color: transparent;
    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
.project-card-loader {
  height: var(--cardLoaderHeightSM);
  background-color: var(--white100);
}

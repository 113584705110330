.input {
  height: var(--controlSizeLG);

  border-radius: var(--controlBorderRadius);
  font-size: var(--inputFontSize);
  line-height: var(--inputLineHeight);

  padding: var(--inputPaddingVertical) var(--inputPaddingHorizontal);

  font-family: var(--primaryFont);
  font-weight: var(--normal);

  color: var(--black100);

  &.white {
    background: var(--white100);
  }

  &:focus {
    box-shadow: none;
  }

  &.error,
  &.error > input {
    color: var(--mainError);
    background: var(--bgError);
  }

  &[type='date']:not(.has-value):not(:focus) {
    color: var(--transparent);
    &:before {
      color: var(--black48);
      content: attr(placeholder);
    }
  }

  & .ant-input {
    background-color: transparent;
  }
}

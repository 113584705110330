.date-picker {
  width: 100%;
  height: var(--controlSizeLG);

  border-radius: var(--controlBorderRadius);
  font-size: var(--inputFontSize);
  line-height: var(--inputLineHeight);

  padding: var(--inputPaddingVertical) var(--inputPaddingHorizontal);

  font-family: var(--primaryFont);
  font-weight: var(--normal);

  color: var(--black100);

  & input {
    &:first-letter {
      text-transform: capitalize;
    }
  }

  &.white {
    background: var(--white100);
  }

  &:focus {
    box-shadow: none;
  }

  & .ant-picker-input {
    & > input {
      font-family: var(--primaryFont);
      font-weight: var(--normal);
    }
  }

  &.error,
  &.error > input {
    color: var(--mainError);
    background: var(--bgError);
  }

  &[type='date']:not(.has-value):not(:focus) {
    color: var(--transparent);
    &:before {
      color: var(--black48);
      content: attr(placeholder);
    }
  }

  & .ant-picker-suffix {
    color: var(--black);
  }

  & .ant-picker-clear {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  &:hover .ant-picker-suffix,
  &.ant-picker-focused .ant-picker-suffix {
    color: var(--lime);
  }
}

.date-picker-popup {
  & .ant-picker-ok button {
    color: var(--lime);

    background-color: var(--transparent);

    &:disabled {
      color: var(--black48);
    }
    &:hover:not(:disabled) {
      background-color: var(--lime);
    }
  }
}

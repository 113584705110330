.ant-input.textarea {
  border-radius: var(--controlBorderRadius);
  padding: var(--inputPaddingVertical) var(--inputPaddingHorizontal);

  font-size: var(--inputFontSize);
  line-height: var(--inputLineHeight);
  font-family: var(--primaryFont);
  font-weight: var(--normal);

  color: var(--black100);

  resize: none;

  &:focus {
    box-shadow: none;
  }
}

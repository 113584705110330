.ant-modal.modal {
  & .ant-modal-content {
    padding: var(--paddingModal);
    border-radius: 0;
    background-color: var(--white100);

    & .ant-modal-close {
      top: var(--marginBlockModal);
      right: var(--marginBlockModal);

      height: var(--controlHeightSMmobile);
      width: var(--controlHeightSMmobile);
      border-radius: 50%;

      background-color: var(--storm6);
      color: var(--black100);

      &:hover {
        background: var(--pistachio);
        color: var(--white100);
      }

      & .ant-modal-close-x {
        align-items: center;
        justify-content: center;

        transform: scale(0.6667);
      }
    }

    & .ant-modal-header {
      margin-bottom: var(--marginBlockModal);
      background-color: var(--white100);
    }

    & .ant-modal-body {
      margin: 0;
    }

    & .ant-modal-footer {
      margin-top: var(--marginBlockModal);
    }
  }
}

.company-card {
  padding: 24px 32px;
  background-color: var(--storm6);
  &__name {
    h4 {
      font-size: var(--textFontSizeLG);
      margin: 0;
    }
    margin-bottom: 16px;
  }
  &__checkbox {
    display: flex;
    align-content: center;
    gap: 12px;
  }
}

.radio-group {
  margin-top: var(--marginSM);

  &.read-only {
    pointer-events: none;
  }

  & .radio.ant-radio-wrapper {
    & .ant-radio {
      & .ant-radio-inner {
        width: var(--radioControlSize);
        height: var(--radioControlSize);

        background-color: var(--white100);
        border: 1px solid var(--black12);
      }

      &.ant-radio-checked {
        & .ant-radio-inner {
          border: 1px solid var(--lime);

          &::after {
            background-color: var(--lime);
            transform: scale(0.5);
          }
        }

        &.ant-radio-disabled {
          & .ant-radio-inner {
            border: 1px solid var(--pistachio);

            &::after {
              background-color: var(--pistachio);
            }
          }
        }
      }
    }

    &.read-only {
      & .ant-radio {
        & .ant-radio-inner {
          border-color: var(--pistachio);
          border-color: var(--black12);
        }

        &.ant-radio-checked {
          & .ant-radio-inner {
            border: 1px solid var(--pistachio);

            &::after {
              background-color: var(--pistachio);
            }
          }
        }
      }
    }
  }

  &.vertical {
    & .ant-radio-group {
      display: flex;
      flex-direction: column;

      & .radio.ant-radio-wrapper {
        margin-block: var(--paddingMD);
      }
    }
  }
}

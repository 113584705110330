.text {
  width: 100%;

  &.inline {
    width: auto;
    display: inline-flex;
  }

  & span.label {
    font-family: var(--primaryFont);
  }

  &.variant {
    &-black {
      color: var(--black100);
    }

    &-gray span.label {
      opacity: 0.32;
    }

    &-black24 span.label {
      color: var(--black24);
    }

    &-black48 span.label {
      color: var(--black48);
    }
    &-black36 span.label {
      color: var(--black36);
    }

    &-black64 span.label {
      color: var(--black64);
    }

    &-black72 span.label {
      color: var(--black72);
    }

    &-black84 span.label {
      color: var(--black84);
    }

    &-mainError span.label {
      color: var(--mainError);
    }

    &-lime span.label {
      color: var(--lime);
    }
  }

  &.weight {
    &-normal > span.label {
      font-weight: var(--normal);
    }

    &-semibold > span.label {
      font-weight: var(--semibold);
    }
    &-bold > span.label {
      font-weight: var(--bold);
    }
  }

  &.variant-gray:focus-within span.label {
    font-weight: var(--normal);
    opacity: 0.8;
  }

  &.level {
    &-1 span.label {
      font-size: var(--textFontSizeXL);
      line-height: var(--textLineHeightXL);
    }
    &-2 span.label {
      font-size: var(--textFontSizeLG);
      line-height: var(--textLineHeighLG);
    }
    &-3 span.label {
      font-size: var(--textFontSizeMD);
      line-height: var(--textLineHeightMD);
    }
    &-4 span.label {
      font-size: var(--textFontSizeSM);
      line-height: var(--textLineHeightSM);
    }
    &-5 span.label {
      font-size: var(--textFontSizeXS);
      line-height: var(--textLineHeightXS);
    }
  }
}

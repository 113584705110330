.button {
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: var(--controlBorderRadius);

  padding: var(--controlPaddingVertical) var(--controlPaddingHorizontal);

  font-size: var(--fontSize);
  line-height: var(--lineHeight);

  font-family: var(--primaryFont);
  font-weight: var(--medium);

  &.primary {
    background: var(--lime);
    color: var(--white100);
  }

  &.white {
    background: var(--white100);
    color: var(--black100);
  }

  &.secondary {
    background: var(--storm6);
    color: var(---black100);

    &.withBorder {
      border: 2px solid var(--lime);
      &:hover {
        border: 2px solid var(--pistachio);
      }
    }
  }

  &.white {
    background-color: var(--white100);
  }

  &.loading {
    pointer-events: none;
  }

  &.wide {
    width: 100%;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    background: var(--pistachio);

    &.secondary {
      color: var(--white100);
    }
  }

  &:active:not(:disabled) {
    background: var(--lime);
    color: var(--white100);
  }

  &:disabled {
    opacity: 0.3;

    cursor: not-allowed;
  }

  &.large {
    height: var(--controlSizeLG);
  }

  &.medium {
    height: var(--controlSizeMD);
  }

  &.small {
    height: var(--controlSizeSM);
    font-size: var(--fontSizeSM);
  }

  &.icon {
    padding: 0;
    &.large {
      width: var(--controlSizeLG);
    }

    &.medium {
      width: var(--controlSizeMD);
    }

    &.small {
      width: var(--controlSizeSM);
    }
  }

  &.icon-text {
    padding: 0;
    background-color: transparent;
    color: var(---black100);
    &:hover,
    .ant-table-cell-row-hover & {
      color: var(--lime);
      background-color: transparent;
    }
    &.large {
      width: var(--controlSizeLG);
    }

    &.medium {
      width: var(--controlSizeMD);
    }

    &.small {
      width: var(--controlSizeSM);
    }
  }

  @media (max-width: 30em) {
    &.large {
      height: var(--controlHeightLGmobile);
    }

    &.medium {
      height: var(--controlHeightMDmobile);
    }

    &.small {
      height: var(--controlHeightSMmobile);
    }
  }

  & .button-prefix-icon {
    display: flex;
    align-items: center;
    margin-right: var(--marginXS);

    width: var(--iconSizeMD);
    height: var(--iconSizeMD);

    transform: scale(0.83333);
  }

  &.small .button-prefix-icon {
    transform: scale(0.666667);
  }
}

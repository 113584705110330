.link {
  cursor: pointer;

  color: var(--black72);

  font-size: var(--textFontSizeSM);
  font-weight: var(--semibold);
  line-height: var(--textLineHeightXXS);

  max-width: 100%;
  text-decoration: underline;
  text-decoration-color: var(--black64);
}

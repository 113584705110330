@import './assets/fonts/fonts.scss';

html,
body {
  height: 100%;
  width: 100%;

  overflow-y: hidden;
}

#root {
  width: 100vw;
  height: 100%;
  overflow-y: auto;
}

::view-transition-old(root),
::view-transition-new(root) {
  animation-duration: 0.4s;
}

.content-centered-container {
  position: relative;
  left: calc(50vw - 50%);
  max-width: var(--maxLayoutWidth);
}

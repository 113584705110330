.bills-list-actions-loader {
  height: var(--cardActionLoaderHeight);
  background-color: var(--white100);
  border-radius: var(--controlBorderRadius);
  margin-bottom: var(--marginLG);
}

.bills-list-loader {
  height: var(--cardLoaderHeightXL);
  background-color: var(--white100);
  border-radius: var(--controlBorderRadius);
  margin-bottom: var(--marginLG);
}

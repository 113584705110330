.time-picker-range {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;

  gap: var(--paddingMD);

  & .time-picker-range-pickers {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    gap: var(--marginLG);
  }

  & .time-picker {
    width: auto;
    flex: 1;
  }
}

.time-picker-popup.ant-picker-dropdown {
  & .ant-picker-now {
    display: none;
  }
}

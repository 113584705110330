.table.ant-table-wrapper {
  font-size: var(--textFontSizeMD);
  line-height: var(--textLineHeightMD);
  & .ant-table-thead {
    & > tr > th {
      background-color: var(--white100);

      font-weight: var(--normal);
      color: var(--black48);
      font-size: var(--textFontSizeMD);
      line-height: var(--textLineHeightMD);

      &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        content: none;
      }
    }
  }

  & .ant-table-body {
    & .ant-table-cell {
      font-size: var(--textFontSizeMD);
      line-height: var(--textLineHeightMD);
    }
  }
}

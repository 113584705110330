.checkbox-wrapper {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--marginXXL);

  gap: var(--paddingMD);

  & .checkbox-label {
    cursor: pointer;

    font-size: var(--textFontSizeMD);
    line-height: var(--textLineHeightSM);

    font-weight: var(--normal);
  }

  & .checkbox {
    position: relative;
    flex-shrink: 0;

    width: var(--radioControlSize);
    height: var(--radioControlSize);

    & .checkbox-check-icon {
      position: absolute;

      height: 100%;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;

      border: 1px solid var(--black12);
      color: transparent;
      background-color: var(--white100);

      transition: all 0.22s ease-in-out;
    }

    & input[type='checkbox'].checkbox-input {
      position: absolute;
      z-index: 1;
      cursor: pointer;

      inset: 0;
      opacity: 0;
      margin: 0;

      &:disabled {
        cursor: not-allowed;
      }

      &:checked + .checkbox-check-icon {
        color: var(--white100);
        background-color: var(--lime);
        border-color: var(--lime);
      }

      &:disabled:checked + .checkbox-check-icon {
        background-color: var(--pistachio);
        border-color: var(--pistachio);
      }
    }

    &:hover .checkbox-check-icon {
      color: var(--black12);
    }
  }
}

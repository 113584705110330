.loader {
  display: flex;

  align-items: center;
  justify-content: center;

  width: 100%;
  height: auto;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.debt-repayment-loader {
  height: var(--cardLoaderHeightXL);
  background-color: var(--white100);
}

.debt-repayment-schedule {
  background-color: var(--white100);
  padding: var(--paddingXXL);

  & h4 {
    margin-bottom: 0;
  }

  & .debt-info {
    margin-block: var(--marginXL);
    & .debt-info-col > * {
      width: auto;
    }
  }

  & .debt-repayment-schedule-list {
    background-color: var(--storm6);
    padding: var(--paddingXL);

    & > * {
      width: auto;
    }
  }
}

@import '@/assets/styles/dropdown';

// TODO: стилизовать
.select-wrapper {
  position: relative;

  & .ant-select-auto-complete {
    z-index: 10;

    &.ant-select-single,
    &.ant-select-multiple {
      width: 100%;

      & .ant-select-selection-search-input {
        background-color: transparent;
        border: none;
        outline: none;
      }

      & .ant-select-selector {
        cursor: pointer;

        background-color: var(--storm6);
        padding: var(--inputPaddingVertical) var(--inputPaddingHorizontal);
        border-radius: var(--controlBorderRadius);

        height: var(--controlSizeLG);

        overflow: hidden;

        & .ant-select-selection-placeholder {
          color: var(--black48);
          padding: 0 var(--inputPaddingHorizontal);
        }

        & .ant-select-selection-search {
          position: absolute;
          top: 0;
          bottom: 0;
          inset-inline-start: 0;
          inset-inline-end: 0;
          padding: var(--inputPaddingVertical) var(--inputPaddingHorizontal);

          & .ant-select-selection-search-input {
            cursor: pointer;

            font-size: var(--inputFontSize);
            line-height: var(--inputLineHeight);

            font-family: var(--primaryFont);
            font-weight: var(--normal);

            color: var(--black100);
          }
        }

        & .ant-select-selection-overflow {
          gap: 1em 0.3em;

          & .ant-select-selection-overflow-item {
            &.ant-select-selection-overflow-item-rest {
              & .ant-select-selection-item {
                color: var(--black36);
                background-color: var(--transparent);
              }
            }
          }
        }
      }

      &.ant-select-open {
        & .ant-select-selector {
          border: 1px solid var(--black12);
        }

        & .ant-select-arrow {
          transform: rotate(180deg);
        }
      }

      & .ant-select-arrow {
        inset-inline-end: var(--inputPaddingHorizontal);

        width: var(--iconSizeLG);
        height: var(--iconSizeLG);

        margin-top: var(--negativeMarginSM);
        color: var(--black100);

        transition: transform 0.22s ease-in;
      }

      &.ant-select-status-error {
        & .ant-select-selector {
          background-color: var(--bgError);
          border: 1px solid var(--mainError);

          & > * {
            color: var(--mainError);
          }
        }
      }

      &.read-only {
        & .ant-select-arrow {
          color: var(--black24);
        }
      }
    }

    &.ant-select-multiple {
      & .ant-select-selection-search {
        opacity: 0;
      }
    }

    &.white {
      & .ant-select-selector {
        background-color: var(--white100);
      }
    }
  }

  & .select-menu-top-div {
    @include select-menu-div();

    &.white {
      @include select-menu-div(--white100);
    }
  }

  .select-error-icon {
    z-index: 11;
    color: var(--mainError);
    position: absolute;

    right: var(--inputPaddingHorizontal);
    top: var(--paddingLG);
  }
}

.select-popup {
  @include drop-down-menu();

  &.white {
    @include drop-down-menu(var(--white100));
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.listContainer {
  position: relative;
}

.list {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--storm12);
  width: 100%;
  z-index: 100;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;

  &_hidden {
    border: 0;
  }
}

.item {
  padding: 14px 24px;
  background-color: var(--white100);
  font-weight: var(--normal);
  color: var(--black100);
  font-size: var(--textFontSizeMD);
  line-height: var(--textLineHeightMD);
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    background-color: var(--storm12);
  }
}

.card {
  background-color: var(--storm6);
  display: flex;
  flex-direction: column;
  padding: 12px;
  cursor: pointer;
  gap: 8px;

  .name {
    font-size: var(--fontSizeSM);
    font-weight: 700;
    font-family: var(--primaryFont);
  }

  .info {
    color: var(--black84);
    font-family: var(--primaryFont);
  }
}

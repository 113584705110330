.button-loader {
  display: flex;
  justify-content: space-between;

  height: 9px;
  width: 40px;

  .dot {
    width: 9px;
    height: 9px;

    border-radius: 50%;

    .primary &.dot {
      background-color: var(--white100);
    }
    .secondary &.dot {
      background-color: var(--black100);
    }
  }

  .dot:nth-child(1) {
    animation: dotScale 2s infinite linear;
    animation-delay: 0s;
  }

  .dot:nth-child(2) {
    animation: dotScale 2s infinite linear;
    animation-delay: 0.5s;
  }
  .dot:nth-child(3) {
    animation: dotScale 2s infinite linear;
    animation-delay: 1s;
  }

  @keyframes dotScale {
    0%,
    100% {
      transform: scale(0.55555);
      opacity: 0.5;
    }
    25%,
    75% {
      transform: scale(0.7777);
      opacity: 0.5;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
  }
}

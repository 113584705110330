.container {
  height: 48px;
  flex-wrap: nowrap;
  gap: var(--marginLG);

  .info {
    flex-wrap: nowrap;
    height: 100%;
  }

  .title {
    font-family: var(--primaryFont);
    font-size: var(--fontSize);
    font-weight: var(--normal);
    line-height: (--textLineHeightMD);
  }

  .amount {
    font-family: var(--primaryFont);
    font-weight: var(--semibold);
    font-size: var(--fontSize);
  }

  .percentage {
    font-family: var(--primaryFont);
    font-size: var(--fontSizeSM);
  }

  .line-container {
    display: inline-flex;
    height: 100%;
    flex-wrap: nowrap;
    gap: var(--marginLG);
  }

  .line {
    font-family: var(--primaryFont);
    background-color: var(--lime);
    height: 100%;
    width: 80%;
    &.highlighted {
      background-color: var(--white84);
    }
  }
}

.tabs.ant-tabs {
  & .ant-tabs-nav {
    margin-bottom: 0;

    &::before {
      display: none;
    }

    & .ant-tabs-nav-list {
      & .ant-tabs-ink-bar {
        display: none;
      }

      & .ant-tabs-tab {
        margin: 0;
        padding: var(--controlPaddingVertical) var(--controlPaddingHorizontal);

        font-family: var(--secondaryFont);
        font-size: var(--textFontSizeXS);
        font-weight: var(--bold);

        &.ant-tabs-tab-active {
          text-shadow: none;
          background-color: var(--white100);

          & .ant-tabs-tab-btn {
            color: var(--black100);
          }
        }

        & .ant-tabs-tab-btn {
          color: var(--black64);
        }
      }
    }
  }
}

.current-commitments-loader {
  height: var(--cardLoaderHeightXL);
  background-color: var(--white100);
}

.current-commitments {
  background-color: var(--white100);
  padding: var(--paddingXXL);

  & .current-commitments-list {
    background-color: var(--storm6);
    padding: var(--paddingXL);

    & > * {
      width: auto;
    }
  }
}

.input-number-container {
  position: relative;

  input[type='text'].input-number {
    position: relative;
    z-index: 10;

    width: 100%;

    border: var(--noBorder);
    height: var(--controlSizeLG);

    padding: var(--inputPaddingVertical) var(--inputPaddingHorizontal);

    border-radius: var(--controlBorderRadius);

    background-color: var(--storm6);

    font-size: var(--inputFontSize);
    line-height: var(--inputLineHeight);

    font-family: var(--primaryFont);
    font-weight: var(--normal);

    color: var(--black100);

    &::placeholder {
      color: var(--black48);
    }

    &:focus,
    &:active {
      outline: none;
    }

    &.error {
      color: var(--mainError);
      background: var(--bgError);
      border: 1px solid var(--mainError);

      &::placeholder {
        color: var(--mainError);
      }
    }
  }

  .input-number-icon {
    z-index: 11;
    color: var(--mainError);
    position: absolute;

    right: var(--inputPaddingHorizontal);
    top: var(--paddingLG);
  }
}
